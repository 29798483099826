import { persisted } from 'svelte-persisted-store';

export const shoppingBag = persisted('shoppingBag', {
	lineItems: [],
	count: 0,
	totalAmount: 0
});

export const addToShoppingBag = (item) => {
	shoppingBag.update((shoppingBag) => {
		let existingLineItem = shoppingBag.lineItems.find(
			(lineItem) =>
				lineItem.name === item.name &&
				lineItem.variation === item.variation &&
				lineItem.size === item.size
		);

		if (existingLineItem) {
			existingLineItem.quantity += item.quantity;
			existingLineItem.amount += item.amount * item.quantity;
		} else {
			item.amount *= item.quantity;
			shoppingBag.lineItems.push(item);
		}

		shoppingBag.count = shoppingBag.lineItems.reduce((total, item) => total + item.quantity, 0);
		shoppingBag.totalAmount = shoppingBag.lineItems.reduce((total, item) => total + item.amount, 0);

		return shoppingBag;
	});
};

export const removeFromShoppingBag = (item) => {
	shoppingBag.update((shoppingBag) => {
		let existingLineItem = shoppingBag.lineItems.find(
			(lineItem) =>
				lineItem.name === item.name &&
				lineItem.variation === item.variation &&
				lineItem.size === item.size
		);

		if (existingLineItem) {
			existingLineItem.quantity -= item.quantity;
			existingLineItem.amount -= item.amount * item.quantity;
		}

		shoppingBag.lineItems = shoppingBag.lineItems.filter((lineItem) => lineItem.quantity > 0);
		shoppingBag.count = shoppingBag.lineItems.reduce((total, item) => total + item.quantity, 0);
		shoppingBag.totalAmount = shoppingBag.lineItems.reduce((total, item) => total + item.amount, 0);

		return shoppingBag;
	});
};

export const resetShoppingBag = () => {
	shoppingBag.update((shoppingBag) => {
		shoppingBag.lineItems = [];
		shoppingBag.count = 0;
		shoppingBag.totalAmount = 0;

		return shoppingBag;
	});
};
